import axios from 'axios';
import queryString from 'query-string';
import { message } from 'antd';
import accountMethods from '@/common/account/methods';

const createHmac = require('create-hmac');

export const baseURL = '/api';

const server = axios.create({
  baseURL,
  timeout: 20000
});

const commonClientId =
  process.env.NEXT_PUBLIC_NODE_ENV === 'production' ? 26 : 90;
const froms = ['share', 'mathgpt', 'external'];
server.interceptors.request.use(
  (options) => {
    const configs = { ...options };
    const time = new Date().getTime().toString();
    const hmac = createHmac('sha1', 'ai-platform-class-fx');
    hmac.update(time, 'utf8');
    const h = hmac.digest('base64');
    const { from } = queryString.parse(window.location.search);
    const clientId =
      from === 'shenxiu' && configs.url.includes('/identity/user/login')
        ? { 'X-UCenter-ClientId': commonClientId }
        : {};
    configs.headers = {
      ...options.headers,
      auth: h,
      timeTmp: time,
      access_token: froms.includes(from)
        ? localStorage.getItem('access_token')
        : accountMethods.getToken(),
      ...clientId
    };
    return configs;
  },
  (error) => {
    return Promise.reject(error);
  }
);
const handleUnvalid = () => {
  if (/MathGPT/.test(window.location.href)) {
    const { from } = queryString.parse(window.location.search);
    const isExternal = from === 'external';
    const tokenName = isExternal ? 'access_token' : 'x-user-token';
    if (localStorage.getItem(tokenName)) {
      message.error('登录已过期');
      localStorage.removeItem(tokenName);
    }
    setTimeout(() => {
      window.location.href = !isExternal
        ? 'https://sso.zhiyinlou.com/portal/login/894489529?redirectUrl=https://aigc.tal.com/'
        : '/account/login?from=mathgpt';
    }, 2000);
  }
};
server.interceptors.response.use(
  (res) => {
    const { data } = res;
    // 兼容/identity/user/login接口code = 0
    if (
      /^2\d+/.test(data.code) ||
      res.config.url.includes('/identity/user/login')
    ) {
      return Promise.resolve(data);
    }
    return Promise.reject(data || res.msg || '');
  },
  (error) => {
    const errorStatus = error.request && error.request.status;
    let errorMsg = '';
    if (errorStatus || errorStatus === 0) {
      switch (errorStatus) {
        case 401:
          errorMsg = '登录已过期';
          handleUnvalid();
          break;
        case 403:
          errorMsg = '403 禁止访问页面，请查看其他页面';
          break;
        case 404:
          errorMsg = '404 没有找到相应内容，请查看其他页面';
          break;
        case 500:
          errorMsg = '500 服务器异常，请稍后重试';
          break;
        case 502:
          errorMsg = '502 请求失败，请稍后重试';
          break;
        case 0:
          errorMsg = '服务器连接超时，请重新尝试';
          break;
        default:
          errorMsg = '服务器异常，请重新尝试';
      }
    }
    return Promise.reject(new Error(errorMsg));
  }
);

export default server;
